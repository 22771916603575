var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"Container_Nivel2_Notas",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"Barra_Pesquisa mx-4",attrs:{"append-icon":"search","label":("" + (_vm.$tc('label.pesquisar', 1))),"single-line":"","hide-details":"","clearable":""},on:{"input":_vm.filtrar},model:{value:(_vm.pesquisaTabela),callback:function ($$v) {_vm.pesquisaTabela=$$v},expression:"pesquisaTabela"}})],1),_c('v-container',{staticClass:"Container_Tabela py-3",attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"id":"tabela_notas","headers":_vm.headers,"items":_vm.listaNotasConsolidado,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
                itemsPerPageOptions: [10, 25, 50],
              }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.competencia",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(((item.competencia.substring(4)) + "/" + (item.competencia.substring(0,4))))+" ")]}},{key:"item.valor_bruto",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.valor_bruto))+" ")]}},{key:"item.valor_liquido",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMoney(item.valor_liquido))+" ")]}},{key:"item.acoes",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.detalharConsolidado(item)}}},on),[_c('v-icon',{staticClass:"fa-sm"},[_vm._v("arrow_forward")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.detalhado')))])])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }