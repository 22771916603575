var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"Container_Tabela",attrs:{"fluid":"","px-0":"","py-2":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-container',{staticClass:"Container_Tabela py-3",attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"id":"tabela_notas","headers":_vm.headers,"items":_vm.listaNotasInconsistencia,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
               itemsPerPageOptions: [10, 20, 30, 40, 50],
              }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openReprocessarDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("cached")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.reprocessar', 1)))])])]}},{key:"item.valor",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{key:item.id,attrs:{"close-on-content-click":true,"transition":"scale-transition","origin":"top right","offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("attach_money")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pa-0",attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.quantidade_produto')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.qtdProduto))])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.valor_bruto')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getMoney(item.valorBruto))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.valor_liquido')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getMoney(item.valorLiquido))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.ipi')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getMoney(item.valorIpi))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.icms')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getMoney(item.valorIcms))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('label.pis_cofins')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getMoney(item.valorPisCofins))+" ")])],1)],1)],1)],1)],1)],1)]}},{key:"item.erro",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{key:item.id + 99,attrs:{"close-on-content-click":true,"transition":"scale-transition","origin":"top right","offset-x":"","left":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"pa-0"},[_vm._l((item.erros.value.slice(1, -1).split(', ')),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$tc('label.erro', 1))+" "+_vm._s(index + 1))]),_c('v-list-item-title',[_vm._v(_vm._s(item.slice(1, -1)))])],1)],1)}),_c('v-divider')],2)],1)],1)],1)]}}])})],1)],1)],1)],1)],1),_c('confirm',{ref:"reprocessarDialog",attrs:{"message":_vm.dialog.content,"persistent":true},on:{"agree":function($event){return _vm.reprocessarInconsistencia()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }